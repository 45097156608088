import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_COUNTRIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SIDE_EVENTS } from 'app-customs/config/dataConfig';
import config from 'app-customs/config/config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY,
// LOGIN_PAGE_KEY,
MOBIGEO_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import AdBanner from 'src/components/ad-banner/AdBanner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] ';
var _require = require('./profiles'),
  DEFAULT_PROFILE = _require.DEFAULT_PROFILE;

// NB: used for ChooseProfile page too !
export var getStyle = function getStyle() {
  return {
    backgroundImage: "url(".concat(getUrl('files/project/home/fond.png'), ")"),
    // fond.png
    backgroundRepeat: 'no-repeat'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 3
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.9,
  // rowspan
  width: 1 // colspan
};

var tileBgGradient = '#F2BE54';
// 'linear-gradient(to bottom,rgba(252, 252, 252, 0.1), rgba(245, 245, 245, 0.9))';
var styleContainerItem = {
  backgroundImage: tileBgGradient,
  // boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
  margin: '0.1rem',
  borderRadius: '0px' // '4px',
};

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem(
  // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  homebutton["label_".concat(lang)],
  // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)],
      target = homebutton["target"];
    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }
  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.miseEnRelation',
  // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
}
var getEventsTile = function getEventsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/programme.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.programme',
  // Page & props
  LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  // LIST_PAGE_KEY,
  {
    input: {
      dataType: DATA_TYPE_EVENTS
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getSideEventsTile = function getSideEventsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/ca_se_passe_ici.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.sideEvent',
  // Page & props
  LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  // LIST_PAGE_KEY,
  {
    input: {
      dataType: DATA_TYPE_SIDE_EVENTS
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getServicesTile = function getServicesTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/infos_pratiques.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.practicalInfo',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_SERVICES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};

/**
 * Generate item to open LiveWall webapp (inapp-browser)
 */
var getItemLiveWall = function getItemLiveWall(orientation) {
  return createItem(
  // style:
  {
    container: {
      className: 'centered-labels home-tile-row3',
      style: {
        backgroundColor: '#203A47'
      }
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/livewall.svg') + ')'
      },
      className: 'home-icon-livewall'
    }
  },
  // Label (i18n)
  'home.livewall',
  // action:
  function (actions) {
    var url = 'https://www.livewall.fr/mobile/mobile-message-5.php?id_event=995';
    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  },
  // Tile options
  // if more than 3 columns are displayed, livewall icon should use only 1 column
  {
    height: 1,
    width: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 3 : 1
  });
};

/**
 * Generate item to open Malllette webapp (inapp-browser)
 */
var getItemMallette = function getItemMallette() {
  return createItem(
  // style:
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/malette.svg') + ')'
      }
      //className: 'home-icon-livewall',
    }
  },
  // Label (i18n)
  'home.mallette',
  // action:
  function (actions) {
    var url = 'https://congreshlm.fr/lamallette/';
    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  },
  // Tile options
  // if more than 3 columns are displayed, livewall icon should use only 1 column
  GENERAL_TILE_OPTIONS);
};

/**
 * Generate item to open Malllette webapp (inapp-browser)
 */
var getItemVote = function getItemVote() {
  return createItem(
  // style:
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        //backgroundImage: 'url(' + getUrl('files/project/home/livewall2.svg') + ')',
        maskImage: "url(".concat(getUrl('files/project/home/votes_et_commentaires.svg'), ")"),
        WebkitMaskImage: "url(".concat(getUrl('files/project/home/votes_et_commentaires.svg'), ")"),
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: '40%',
        backgroundColor: 'white',
        maskRepeat: 'no-repeat',
        maskSize: '40%'
      }
      //className: 'home-icon-livewall',
    }
  },
  // Label (i18n)
  'home.vote',
  // action:
  function (actions) {
    var url = 'https://www.livewall.fr/mobile/clients/ush/';
    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  },
  // Tile options
  // if more than 3 columns are displayed, livewall icon should use only 1 column
  GENERAL_TILE_OPTIONS);
};
var getExhibitorsTile = function getExhibitorsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/exposants.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.exhibitors',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getNewProductTile = function getNewProductTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Innovations.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.newproducts',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getSpeakersTile = function getSpeakersTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/intervenants.svg'), ")")
      }
      // className: 'home-icon-intervenants',
    }
  },
  // Label (i18n)
  'home.speakers',
  // Page & props
  LIST_PAGE_KEY, {
    contextualTitle: '',
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getSocialMediaTile = function getSocialMediaTile() {
  return (
    // createItemRoute(
    createItemRoute(
    // Style
    {
      container: {
        style: styleContainerItem,
        className: 'home-btn-text'
      },
      icon: {
        style: {
          backgroundImage: "url(".concat(getUrl('files/project/home/reseaux_sociaux.svg'), ")")
        }
      }
    },
    // Label (i18n)
    'home.medias',
    // Page & props
    MEDIAS_PAGE_KEY, {
      controlMediaPageContent: {
        // hideTabMediaSocial: true,
        mediaTabKey: 'twitter'
      }
    },
    // Tile options
    GENERAL_TILE_OPTIONS)
  );
};
var getMapTile = function getMapTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/plan.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.map',
  // Page & props
  MOBIGEO_PAGE_KEY, null,
  // Tile options

  GENERAL_TILE_OPTIONS);
};
var getNotifications = function getNotifications() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.notifications',
  // Page & props
  INBOX_PAGE_KEY, null,
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getVideos = function getVideos() {
  return createItemRoute(
  // Style
  {
    container: {
      style: styleContainerItem,
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.videos',
  // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'yoututwbe' // to match index of social Media
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getDefault = function getDefault(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
    // component: AdBanner,
    // props: {
    //   ad: true,
    //   adFiles: adConfig.adsFiles,
    // },
  }, {
    component: HomeToolbar,
    props: {}
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Programme
      getEventsTile(),
      // side event
      getSideEventsTile(),
      // Speakers
      getSpeakersTile(),
      // Exposants
      getExhibitorsTile(),
      // PLAN
      getMapTile(),
      // INFOS PRATIQUES
      getServicesTile(),
      // Social Medias
      getSocialMediaTile(), getItemMallette(), getItemVote()
      //hasAdButtons && adConfig.homebuttons.homebutton1 ? getItemLiveWall(orientation): null,

      // Innovations
      // getNewProductTile(),

      // Mise en relation
      // getParticipantsTile(),

      // Notifications
      // getNotifications(),

      // Videos
      // getVideos(),

      // INTERACTIVITE ATELIERS / LiveWall
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  },
  /* {
    component: MobilespotBar,
  }, */
  {
    component: IphonexFlexMargin
  }];
};

// const getExhibitor = (lang, adConfig, orientation, isLoggedIn) => {
//   const hasAdButtons =
//     adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';

//   return [
//     {
//       component: AdSwap,
//       props: {
//         adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER,
//       },
//     },
//     {
//       component: HomeToolbar,
//       props: {
//       },
//     },
//     {
//       component: HomeTiles,
//       props: {
//         baseColumnCount: BASE_COLUMN_COUNT[orientation],
//         maxColumnSize: MAX_COLUMN_SIZE[orientation],
//         tiles: [
//           // Exposants
//           getExhibitorsTile(),

//           // Programme
//           getEventsTile(),

//           // Speakers
//           getSpeakersTile(),

//           // PLAN
//           getMapTile(),

//           // Mise en relation
//           getParticipantsTile(),

//           // INFOS PRATIQUES
//           getServicesTile(),

//           // Notifications
//           getNotifications(),

//           // Social Medias
//           getSocialMediaTile(),

//           // Videos
//           getVideos(),

//           // INTERACTIVITE ATELIERS
//           // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
//         ],
//       },
//     },
//     {
//       component: AdSwap,
//       props: {
//         adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
//       },
//     },
//     {
//       component: MobilespotBar,
//     },
//     {
//       component: IphonexFlexMargin,
//     },
//   ];
// };

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */
export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  return getDefault(lang, adConfig, orientation, isLoggedIn);
  // switch (profile) {
  //   // PRO
  //   case 'visitor':
  //     return getDefault(lang, adConfig, orientation, isLoggedIn);
  //   // GRAND PUBLIC
  //   case 'exhibitor':
  //     return getExhibitor(lang, adConfig, orientation, isLoggedIn);
  //   default:
  //     if (!profile) {
  //       console.warn(LOG_PREF + 'No profile set yet');
  //     } else {
  //       console.error(LOG_PREF + 'Unsupported profile: ' + profile);
  //     }
  // }
}